<template>
  <v-container fluid>
    <v-tabs v-model="activeTab" class="my-5">
      <v-tab key="membresias"> Histórico de Membresías </v-tab>

      <v-tab key="eventos"> HISTÓRICO DE CURSOS Y EVENTOS </v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTab">
      <v-tab-item key="membresias">
        <!-- contenido de la pestaña "Histórico de Membresías" -->
        <div class="filter-container mt-5 mx-5">
          <span class="titlehist">Histórico de Membresías</span>
          <!-- barra de navegación -->
          <div class="searchbar d-flex flex-row my-4">
            <v-btn
              fab
              small
              elevation="0"
              class="rounded-pill rounded-r-0"
              color="#feb300"
              @click="filterItems()"
            >
              <v-icon size="20">mdi-magnify</v-icon>
            </v-btn>
            <input
              type="text"
              placeholder="Buscar en membresías"
              class="rounded-pill rounded-l-0"
              v-model="searchField"
            />
          </div>
          <!-- información -->
          <v-simple-table height="100%" class="mx-10">
            <thead>
              <tr>
                <th
                  v-for="(title, i) in tableTitles"
                  :key="i"
                  class="text-left"
                >
                  {{ title }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(cont, i) in tableContentfilt"
                :key="i"
                class="text-left"
              >
                <!-- Columna de filtros -->
                <!-- <th class="px-0"></th> -->
                <th>
                  {{ cont.name }}
                </th>
                <th>
                  {{ cont.inicio }}
                </th>
                <th>
                  {{ cont.exp == "" ? "---" : cont.exp }}
                </th>
                <th class="text-uppercase">
                  {{ cont.type }}
                </th>
                <th>
                  {{ cont.costo }}
                </th>
                <!-- <th>
                  <span
                    class="text-decoration-underline"
                    :class="cont.estado"
                  >
                    {{ cont.estado }}
                  </span>
                </th> -->
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-tab-item>

      <v-tab-item key="eventos">
        <div class="filter-container mt-5 mx-5">
          <span class="titlehist pb-10">Histórico de Cursos y Eventos</span>
          <!-- Barra de búsqueda para eventos -->
          <div class="searchbar d-flex flex-row my-4">
            <v-btn
              fab
              small
              elevation="0"
              class="rounded-pill rounded-r-0"
              color="#feb300"
              @click="filterEvents()"
            >
              <v-icon size="20">mdi-magnify</v-icon>
            </v-btn>
            <input
              type="text"
              placeholder="Buscar en eventos"
              class="rounded-pill rounded-l-0"
              v-model="searchField2"
            />
          </div>
          <!-- Tabla para el histórico de eventos -->
          <v-simple-table height="300px" class="mx-10 pt-1">
            <thead>
              <tr>
                <th
                  v-for="(title, i) in eventTableTitles"
                  :key="i"
                  class="text-left"
                >
                  {{ title }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(event, index) in eventTableDataFiltered"
                :key="index"
                class="text-left"
              >
                <td>{{ event.startDate.slice(0, 10) }}</td>
                <td>{{ event.endDate.slice(0, 10) }}</td>
                <td>{{ event.name }}</td>
                <td>{{ event.price }}</td>
                <td>{{ event.membersOnlyTruePrice || 0 }}</td>
                <td>{{ event.membersOnlyFalsePrice || 0 }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      searchField: "",
      searchField2: "",
      tableTitles: [
        "Nombre",
        "Inicio",
        "Expiración",
        "Concepto",
        "Costo",
      ],
      tableContent: [],
      organizationType: {
        'FIRST_PAYMENT': 'Pago inicial',
        'HEAD_OFFICE': 'Renovación',
        'BRANCH': 'Membresía Local',
        'INSCRIPTION': 'Inscripción'
      },
      statusType: {
        'ACTIVE': 'Activo',
        'EXPIRED': 'Expirado',
        'INACTIVE': 'Inactivo'
      },
      tableContentfilt: [],

      activeTab: "membresias",
      eventTableTitles: [
        "Fecha de Inicio",
        "Fecha de Finalización",
        "Nombre del evento",
        "Costo",
        "Precio para miembros",
        "Precio para no miembros",
      ],
      eventTableData: [],
      isEventDataLoaded: false,
      eventSearchField: "",
      eventTableDataFiltered: [],
    };
  },
  methods: {
    ...mapActions("member", ["fetchMemberItems"]),
    ...mapMutations("loader", ["loading", "loaded"]),

    filterItems() {
      let ft = this.searchField;
      this.searchField = "";
      this.tableContentfilt = this.tableContent.filter((cont) => {
        return (
          cont.estado.toLowerCase().includes(ft.toLowerCase()) ||
          cont.costo.toLowerCase().includes(ft.toLowerCase()) ||
          cont.memLev.toLowerCase().includes(ft.toLowerCase())
        );
      });
    },
    filterEvents() {
      const searchText = this.searchField2.toLowerCase();
      this.searchField2 = "";
      this.eventTableDataFiltered = this.eventTableData.filter((event) => {
        return (
          event.name.toLowerCase().includes(searchText) ||
          event.startDate.slice(0, 10).includes(searchText) ||
          event.endDate.slice(0, 10).includes(searchText) ||
          event.costo.includes(searchText)
        );
      });
    },

    async fetchMemberEvents() {
      try {
        this.loading();
        const response = await axios.get("/members/event-orders");

        if (!response.data) {
          this.eventTableData = [];
        } else {
          this.eventTableData = response.data.map((event) => {
            const membersOnlyTruePrice =
              event.membersOnlyTruePrice !== undefined &&
              event.membersOnlyTruePrice !== 0
                ? "$" + event.membersOnlyTruePrice + " MXN"
                : "$" + event.price + " MXN";

            const membersOnlyFalsePrice =
              event.membersOnlyFalsePrice !== undefined &&
              event.membersOnlyFalsePrice !== 0
                ? "$" + event.membersOnlyFalsePrice + " MXN"
                : "$" + event.price + " MXN";

            return {
              price: "$" + event.price + " MXN",
              name: event.name,
              startDate: event.startDate,
              endDate: event.endDate,
              startTime: event.startTime,
              endTime: event.endTime,
              membersOnlyTruePrice,
              membersOnlyFalsePrice,
            };
          });
        }

        this.isEventDataLoaded = true;

        this.eventTableDataFiltered = this.eventTableData;

        this.loaded();
      } catch (error) {
        console.error(error);
        this.loaded();
      }
    },
  },

  computed: {
    ...mapState("member", ["items"]),
  },

  async mounted() {
    // Llamada a la función fetchMemberEvents después de montar el componente

    // Resto de tu código en el método mounted
    this.loading();
    let branch = localStorage.getItem("affiliationProfile");
    let its = {};
    let objts = {};

    if (!this.items.data) {
      its = (await this.fetchMemberItems(branch)).data.items;
    } else {
      its = this.items.data.items;
    }
    try {
      this.fetchMemberEvents();

      objts = its.map((ob) => {
        console.log( ob.status);
        let item = {
          name: ob.affiliation ? ob.affiliation.name : ob.affiliationInscription ? ob.affiliationInscription.description: '',
          inicio: ob.createdAt.split("T").shift(),
          exp: "2023-05-23",
          type: this.organizationType[ob.type] ,
          costo: ob.total ? `$${ob.total} MXN` : ob.order ? `$${ob.order.total} MXN` :  "$0 MXN",
          estado: this.statusType[ob.status]
        };

        const { affiliateInformation } = ob;

        if (affiliateInformation) {
          if (
            affiliateInformation.inactiveDate &&
            affiliateInformation.expirationDate
          ) {
            item.exp = moment(affiliateInformation.inactiveDate).format(
              "YYYY-MM-DD"
            );
          } else if (affiliateInformation.inactiveDate) {
            item.exp = moment(affiliateInformation.inactiveDate).format(
              "YYYY-MM-DD"
            );
          } else if (affiliateInformation.expirationDate) {
            item.exp = moment(affiliateInformation.inactiveDate).format(
              "YYYY-MM-DD"
            );
          }
        }
        return item;
      });
      objts.forEach((obj) => {
        this.tableContent.push(obj);
      });
      this.tableContentfilt = this.tableContent;
    } catch (error) {
      console.log(error);
    } finally {
      this.loaded();
    }
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Montserrat-Black";
  src: url("/src/assets/fonts/Montserrat/MONTSERRAT_Montserrat-Black.otf")
    format("otf");
}

.titlehist {
  font-family: "Montserrat-Black", Fallback, sans-serif;
  font-weight: 800;
  font-size: 20px;
}

input::placeholder {
  font-size: 15px;
  color: #d4dcd4;
}

input {
  padding-left: 20px;
  width: 30rem;
  max-width: 30rem;
  min-width: 10rem;
  border: 0;
  border-color: transparent;
  background-color: #f9fafc;
}

input:focus {
  outline: none;
  border: 1px solid transparent;
}

th {
  font-weight: 400;
  font-size: 5px;
}

.Activo {
  color: #7cd6b7;
  font-weight: 600;
}

/* .Expirado {
  color: #b1b1b1;
  font-weight: 600;
}

.Inactivo {
  color: red;
  font-weight: 600
} */
</style>